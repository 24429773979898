import { Layout } from 'components/Layout';
import { PrincipleBenefits } from 'components/Principle/Benefits';
import { PrincipleFeatures } from 'components/Principle/Features';
import { PrinciplesNav } from 'components/PrinciplesNav';
import { graphql } from 'gatsby';
import React from 'react';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { FeatureHighlight } from 'src/components/Principle/FeatureHighlight';
import { PrincipleHero } from 'src/components/Principle/Hero';

export default function EngagementPage({ data }) {
    return (
        <Layout
            pageTitleFull="Engagement: An Audentio Community Principle"
            siteDescription="Contact us for help motivating returning members and encouraging engagement in your online community."
            contactForm
            subnavTitle="Engagement"
            subnav={[
                {
                    id: 'features',
                    label: 'Features',
                },
                {
                    id: 'communityapp',
                    label: 'Community App',
                },
                {
                    id: 'benefits',
                    label: 'Benefits',
                },
            ]}
        >
            <PrincipleHero
                title="Build the desire for a lasting connection"
                subtitle="Engagement"
                image={data.hero.childImageSharp.fluid}
            />

            <PrinciplesNav name="engagement" />

            <PrincipleFeatures
                id="communityapp"
                title="ways to keep them coming back"
                features={[
                    {
                        subtitle: 'experience',
                        title: 'curated feed with a dashboard',
                        content:
                            'Relevant content by followed topics and users delivered right when members login.',
                    },
                    {
                        subtitle: 'tech',
                        title: 'integrate with social media',
                        content:
                            'Help bring followers from other accounts and networks.',
                    },
                    {
                        subtitle: 'content',
                        title: 'upvote and downvote',
                        content:
                            'Reddit-style voting system highlights relevant responses and best answers.',
                    },
                    {
                        subtitle: 'experience',
                        title: 'host contests and giveaways',
                        content:
                            'Engage members to keep them coming back with fun contests and rewarding giveaways.',
                    },
                    {
                        subtitle: 'gaming',
                        title: 'start a streaming schedule',
                        content:
                            'Utilize streaming for gaming and more to create a unique channel that engages users on a regular basis.',
                    },
                    {
                        subtitle: 'content',
                        title: 'monthly or quarterly newsletter',
                        content:
                            'Keep members in the loop with curated articles, new shop items, and more.',
                    },
                ]}
            />

            {/* <CTA
                title="Ready to build a community?"
                buttonText="Contact us"
                href="/contact?source=engagement"
            /> */}

            <FeatureHighlight
                bg="secondaryBackground"
                py={['40px', 'spacing-xl', '80px']}
                mb={0}
                id="engagement"
                subtitle="Engagement"
                image={data.communityApp.childImageSharp.fluid}
                title="Give users the ability to interact in a native mobile experience"
                highlights={[
                    {
                        title: 'reach your audience where they are',
                        content:
                            'Alert with native push notifications on iOS and Android.',
                    },
                    {
                        title: 'be present in app stores',
                        content:
                            'Become visible to a wider audience and a younger demographic.',
                    },
                    {
                        title: 'share the experience',
                        content:
                            'Provide a modern and easy native user experience.',
                    },
                ]}
            />
            <PrincipleBenefits
                mt={0}
                id="benefits"
                title="How engagement can benefit your community"
                // ctaHref="/contact?source=engagement"
                // ctaLabel="Contact us"
                benefits={[
                    'keep users engaged and coming back regularly',
                    'incentivize continued activity',
                    'offer a unique, curated, and relevant experience to the user',
                ]}
            />

            <LandingFeatureGrid
                center
                offset={false}
                sectionHeaderProps={{
                    direction: 'row',
                }}
                flexDirection="column"
                title="Related articles"
                customButton={{
                    text: 'See More',
                    href: 'https://adjourn.audent.io/',
                }}
                featureGridProps={{
                    mx: 0,
                    px: 0,
                    columns: [1, 2, null, 4],
                    spacing: {
                        _: 'spacing-lg',
                    },
                }}
                showContact={false}
                items={[
                    {
                        image: data.activeCommunity.childImageSharp.fluid,
                        heading:
                            'Building an active community through incentivization and gamification',
                        content:
                            'Building a community requires a significant investment, and not just from you. As a community manager, you’re asking people to join, share...',
                        href:
                            'https://adjourn.audent.io/building-an-active-community-through-incentivization-and-gamification-eb0551232b22',
                    },
                    {
                        image:
                            data.communitiesWithStreaming.childImageSharp.fluid,
                        heading:
                            'A look into what we’re doing for communities with streaming',
                        content:
                            'Here at Audentio, the last couple of years for us have been so critical in building our future.Thousands of hours have been poured into...',
                        href:
                            'https://adjourn.audent.io/a-look-into-what-were-doing-for-communities-with-streaming-aaa47d94cdb2',
                    },
                    {
                        image: data.improvedUX.childImageSharp.fluid,
                        heading:
                            'How we improved the forum user experience by learning from social media',
                        content:
                            'Online communities, specifically forums, are struggling to keep up with the modern user experience that large social media platforms...',
                        href:
                            'https://adjourn.audent.io/how-we-can-improve-the-forum-user-experience-41e4e036a27f',
                    },
                    {
                        image: data.contentDiscover.childImageSharp.fluid,
                        heading:
                            'Through the Ages: Content Discoverability UX on Forums',
                        content:
                            'A brief history of UX improvements focused on modernizing and improving classic forum discoverability techniques that XenForo software and others have been using for decades.',
                        href:
                            'https://adjourn.audent.io/through-the-ages-content-discoverability-ux-on-forums-c50a06a8784a',
                    },
                ]}
            />
        </Layout>
    );
}

export const query = graphql`
    query EngagementQuery {
        hero: file(relativePath: { eq: "images/hero/engagement.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        communityApp: file(
            relativePath: { eq: "images/engagement/community-app-2.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        improvedUX: file(
            relativePath: { eq: "images/engagement/improved-ux.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        communitiesWithStreaming: file(
            relativePath: {
                eq: "images/engagement/communities-with-streaming.png"
            }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        contentDiscover: file(
            relativePath: { eq: "images/engagement/content-discover.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        activeCommunity: file(
            relativePath: { eq: "images/engagement/active-community.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
